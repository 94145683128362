<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="订单号：">
				<el-input v-model="filters.orderId" placeholder="订单号" clearable></el-input>
			</el-form-item>
			<el-form-item label="选择时间：">
				<el-date-picker v-model="filters.timeRange" type="daterange" align="right" unlink-panels
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="费用类型：">
				<el-select v-model="filters.type" placeholder="选择费用类型" clearable>
					<el-option :value="0" label="问诊"></el-option>
					<el-option :value="1" label="处方"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="exportExcel">创建Excel导出任务</el-button>
			</el-form-item>
		</el-form>
		<!-- <el-tabs v-model="filters.status" @tab-click="handleClickTab">
			<el-tab-pane v-for="item in orderStatuss" :key="item.label" :label="item.label" :name="item.value"> -->
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="orderId" header-align="center" align="left" label="订单号"></el-table-column>
			<el-table-column prop="bizOrderId" header-align="center" align="left" label="业务单号"></el-table-column>
			<el-table-column prop="transactionId" header-align="center" align="left" label="微信订单号"></el-table-column>
			<el-table-column prop="bizType" header-align="center" align="left" label="业务类型"></el-table-column>
			<el-table-column prop="type" header-align="center" align="left" label="费用类型"></el-table-column>
			<el-table-column prop="body" header-align="center" align="left" label="费用名称"></el-table-column>
			<el-table-column prop="totalFee" header-align="center" align="left" label="订单金额"></el-table-column>
			<el-table-column prop="refundFee" header-align="center" align="left" label="退款金额"></el-table-column>
			<el-table-column prop="createdAt" header-align="center" align="left" label="订单时间"></el-table-column>
					<!-- <el-table-column prop="patientName" header-align="center" align="center" label="患者信息">
						<template slot-scope="scope">
							<div>
								<div>{{scope.row.patientName}}</div>
								<div>{{scope.row.showFullPhone ? scope.row.mobilePhone : scope.row.mobilePhoneHide}}
								</div>
								<div class="click-to-show" @click="clickToShow(scope.row)">
									<a>{{scope.row.showFullPhone ? '点击隐藏' : '点击查看'}}</a>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="doctorName" header-align="center" align="center" label="开方医生"></el-table-column>
					<el-table-column prop="pharmacyName" header-align="center" align="center" label="药房名称">
					</el-table-column>
					<el-table-column prop="logisticsOrderNo" header-align="center" align="center" label="物流信息">
					</el-table-column>
					<el-table-column prop="status" header-align="center" align="center" label="订单状态">
						<template slot-scope="scope">
							{{
								scope.row.status === 1 ? '待支付' :
								scope.row.status === 2 ? '待收货' :
								scope.row.status === 3 ? '已完成' :
								scope.row.status === 4 ? '已取消' :
								scope.row.status === 5 ? '待发货' :
								scope.row.status === 6 ? '已退款' : ''
							}}
						</template>
					</el-table-column>
					<el-table-column prop="totalFee" header-align="center" align="center" label="实付金额">
						<template slot-scope="scope">
							￥{{scope.row.totalFee}}
						</template>
					</el-table-column>
					<el-table-column header-align="center" align="center" label="操作">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="detailHandle(scope.row.id)">订单详情</el-button>
							<el-button
								v-if="scope.row.status === 5"
								type="text"
								size="small"
								@click="outStockHandle(scope.row)"
							>出库</el-button>
							<el-popconfirm
								v-if="scope.row.status === 1"
								title="确认取消这条药品订单？"
								@confirm="cancelHandle(scope.row.id)">
								<el-button style="margin-left: 10px" slot="reference" type="text" size="small">取消</el-button>
							</el-popconfirm>
							<el-button v-if="[2, 5].indexOf(scope.row.status) > -1" type="text" size="small"
								@click="toRefund(scope.row)">退款</el-button>
						</template>
					</el-table-column> -->
		</el-table>
			<!-- </el-tab-pane>
		</el-tabs> -->
		<div style="float: right; margin-top: 20px;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
import { dateFormat } from '@/utils';
export default {
	data () {
		return {
			form: {},
			formLabelWidth: '120px',
			filters: {
				type: '',
				timeRange: '',
				orderId: ''
			},
			orderStatuss: [{
				label: '全部',
				value: '0'
			}],
			depts: [],
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick (picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			if (!this.filters.timeRange) this.filters.timeRange = ['', ''];
			this.$root.request('financialFlow', {
				...this.filters,
				startTime: this.filters.timeRange[0],
				endTime: this.filters.timeRange[1],
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows.map(r => {
						r.type = r.type === 0 ? '问诊' : '处方';
						r.totalFee = '￥' + r.totalFee;
						r.refundFee = r.refundFee ? ('￥' + r.refundFee) : '';
						return r;
					});
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		exportExcel () {
			this.$prompt('请输入Excel文件名', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(({ value }) => {
				if (!this.filters.timeRange) this.filters.timeRange = ['', ''];
				this.$root.request('exportExcelTask', {
					name: value,
					apiName: '/mnt/financial/flow',
					headerModel: [
						{ header: '订单号', key: 'orderId', width: 27 },
						{ header: '业务单号', key: 'bizOrderId', width: 27 },
						{ header: '微信订单号', key: 'transactionId', width: 27 },
						{ header: '业务类型', key: 'bizType', width: 20 },
						{ header: '费用类型', key: 'type', width: 11 },
						{ header: '费用名称', key: 'body', width: 30 },
						{ header: '订单金额', key: 'totalFee', width: 14 },
						{ header: '退款金额', key: 'refundFee', width: 14 },
						{ header: '订单时间', key: 'createdAt', width: 30 }
					],
					dataFormatMap: {
						type: {
							0: '问诊',
							1: '处方'
						}
					},
					options: {
						type: this.filters.type,
						orderId: this.filters.orderId,
						startTime: this.filters.timeRange[0],
						endTime: this.filters.timeRange[1]
					}
				}).then(data => {
					if (data && data.ok === 1) {
						this.$root.elMsg('操作成功');
					}
				});
			});
		}
	}
};
</script>

<style lang="less" scoped>
	.service-cell {
		display: flex;
		align-items: center;

		div {
			text-align: left;
			margin-left: 10px;
		}

		.doctor-avatar {
			width: 100px;
			height: 100px;
		}
	}

	.click-to-show {
		cursor: pointer;
		color: deepskyblue;
	}
</style>